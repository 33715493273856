import router from "@/router";
import { Message } from "element-ui";
// 进度条
import NProgress from "nprogress";
import "nprogress/nprogress.css";

// import { getToken } from '@/utils/auth.js'
router.beforeEach((to, from, next) => {
  NProgress.start();
  // const user = localStorage.getItem("userInfo");
  // console.log("user:", user);
  // to.meta.title
  // window.document.title = to.meta.title === undefined ? '状态异常' : `${to.meta.title}-甘肃文旅大数据`
  // console.log("to---->", to);
  // if (!user && to.path !== "/login") {
  //   Message({
  //     message: "您还未登录",
  //     type: "warning",
  //   });
  //   next("/login");
  //   NProgress.done();
  // } else {
  //   if (user && to.path === "/") {
  //     next("/home");
  //   } else {
  next();
  //   }
  // }
});
router.afterEach((to) => {
  // 进度条
  NProgress.done();
  window.scrollTo(0, 0);
  // 多页控制 打开新的页面
  // store.dispatch('d2admin/page/open', to)

  // util.setTitle(localStorage.getItem('uid'))
  // 更改标题
  // util.title(to.meta.title)
});
// beforeRouteLeave(to, from, next) {
//   // console.log(to)
//   // console.log(from)
//   // 列表页缓存
//   to.meta.keepAlive = false;
//   from.meta.keepAlive = false;
//   next();
// }
