import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "home",
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/components/home/home"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/components/login"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/homeOld",
      name: "homeOld",
      component: () => import("@/components/home/homeOld"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/travelService",
      name: "travelService",
      component: () => import("@/components/travelService/travelService"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/travelManage",
      name: "travelManage",
      component: () => import("@/components/travelManage/travelManage"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/travelMarket",
      name: "travelMarket",
      component: () => import("@/components/travelMarket/travelMarket"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/iframe",
      name: "iframe",
      component: () => import("@/components/iframe/iframe"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/iframeH5",
      name: "iframeH5",
      component: () => import("@/components/iframeH5/iframeH5"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },

    {
      path: "/achievements",
      name: "achievements",
      component: () => import("@/components/achievements/achievements"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/renyuan",
      name: "renyuan",
      component: () => import("@/components/renyuan/renyuan"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/prize",
      name: "prize",
      component: () => import("@/components/prize/prize"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/internet",
      name: "internet",
      component: () => import("@/components/internet/internet"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/zhengce",
      name: "zhengce",
      component: () => import("@/components/zhengce/zhengce"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },

    {
      path: "/mediapage",
      name: "mediapage",
      component: () => import("@/components/mediapage/mediapage"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/shiyanshi",
      name: "shiyanshi",
      component: () => import("@/components/shiyanshi/shiyanshi"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/biaozhunhua",
      name: "biaozhunhua",
      component: () => import("@/components/biaozhunhua/biaozhunhua"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/yijiyou",
      name: "yijiyou",
      component: () => import("@/components/yijiyou/yijiyou"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
    {
      path: "/shuziganbo",
      name: "shuziganbo",
      component: () => import("@/components/shuziganbo/shuziganbo"),
      meta: {
        keepAlive: false, // 组件缓存
        refresh: true, // activated钩子触发时是否重新加载数据
      },
    },
  ],
});
